<template>
  <div>
    <v-container>
      <v-app-bar v-show="!inboxSHOW" fixed flat>
        <v-btn v-show="!inboxSHOW" icon @click.stop="inboxSHOW = !inboxSHOW">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <div v-show="!inboxSHOW">
          <span class=" font-weight-bold">{{ selectedAvatar.title }}</span>
        </div>
      </v-app-bar>

      <v-card flat v-show="!inboxSHOW">
        <v-footer bottom fixed class="white">
          <v-text-field
            class="input-group--focused"
            outlined
            append-icon="mdi-send"
            clearable
            v-model="message"
            placeholder="Type your message here...."
            @click:append="sendMessage"
          ></v-text-field> </v-footer
      ></v-card>

      <v-row>
        <div class="avatarlist" v-show="inboxSHOW">
          <v-list three-line>
            <v-list-item-group v-model="selected" active-class="grey--text">
              <template v-for="(item, index) in items">
                <v-list-item :key="item.title" @click.prevent="clickMe(item)">
                  <v-list-item-avatar size="50">
                    <v-img :src="item.avatar"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="body-2 font-weight-bold"
                      v-html="item.title"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      class="font-weight-light"
                      v-html="item.subtitle"
                      >{{ index }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-avatar v-show="item.msgNo > 0" color="red" size="16">
                      <span
                        class="white--text caption font-weight-bold mx-auto"
                        >{{ item.msgNo }}</span
                      >
                    </v-avatar>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </div>
      </v-row>

      <v-row v-show="!inboxSHOW">
        <v-col>
          <v-list one-line>
            <template v-for="(item, index) in messages">
              <v-list-item class="mb-3" :key="item.title">
                <v-list-item-avatar size="32">
                  <v-img :src="selectedAvatar.avatar"></v-img>
                </v-list-item-avatar>
                <div class="fgOwgm">
                  <v-list-item-content>
                    <span>{{ item.subtitle }}</span>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text
                      v-text="item.action"
                    ></v-list-item-action-text>
                  </v-list-item-action>
                </div>
              </v-list-item>
              <v-divider v-show="0" :key="index"></v-divider>
            </template>
          </v-list>

          <v-list two-line>
            <template v-for="(item, index) in messages">
              <v-list-item class="mb-3" :key="item.title">
                <div class="fcMOyC">
                  <v-list-item-content>
                    <span>{{ item.subtitle }}</span>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text
                      v-text="item.action"
                    ></v-list-item-action-text>
                  </v-list-item-action>
                </div>
              </v-list-item>
              <v-divider v-show="0" :key="index"></v-divider>
            </template>
          </v-list>
          <br />
        </v-col>
      </v-row>
      <br />
      <br />
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    message: "",
    aMEssage: {
      action: "2 min",
      headline: "",
      subtitle: "",
      title: "Messages"
    },
    folders: [
      {
        no: "1",
        subtitle: "Choose a suitable date and time for your first lesson",
        title: "Select time"
      },
      {
        no: "2",
        subtitle:
          "Make a secure online payment by PayPal or Visa/Mastercard paypal iconvisa iconmastercard",
        title: "Make a payment"
      },
      {
        no: "3",
        subtitle: "Meet your tutor for a video call in our virtual classroom",
        title: "Join lesson in the Preply classroom"
      }
    ],
    selectedAvatar: {
      avatar: "",
      title: "",
      subtitle: ""
    },
    windowSize: {
      x: 0,
      y: 0
    },
    inboxSHOW: true,
    selected: [2],
    messages: [
      {
        action: "15 min",
        headline: "Brunch this weekend?",
        subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        title: "Ali Connors"
      },
      {
        action: "2 hr",
        headline: "Summer BBQ",
        subtitle: `Wish I could come, but I'm out of town this weekend.`,
        title: "me, Scrott, Jennifer"
      },
      {
        action: "6 hr",
        headline: "Oui oui",
        subtitle: "Do you have Paris recommendations? Have you ever been?",
        title: "Sandra Adams"
      },
      {
        action: "12 hr",
        headline: "Birthday gift",
        subtitle:
          "Have any ideas about what we should get Heidi for her birthday?",
        title: "Trevor Hansen"
      },
      {
        action: "18hr",
        headline: "Recipe to try",
        subtitle:
          "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
        title: "Britta Holt"
      }
    ],
    items: [
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Brunch this weekend?",
        subtitle: ` Ali Connors  I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        msgNo: "2"
      },

      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: "Summer BBQ",
        subtitle: ` to Alex, Scott, Jennifer  Wish I could come, but I'm out of town this weekend.`,
        msgNo: "0"
      },

      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        title: "Oui oui",
        subtitle:
          " Sandra Adams Do you have Paris recommendations? Have you ever been?",
        msgNo: "1"
      },

      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
        title: "Birthday gift",
        subtitle:
          " Trevor Hansen  Have any ideas about what we should get Heidi for her birthday?",
        msgNo: "3"
      },

      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle:
          " Britta Holt  We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
        msgNo: "0"
      }
    ]
  }),

  methods: {
    sendMessage() {
      this.aMEssage.subtitle = this.message;
      this.messages.push(this.aMEssage);
      this.messages = "";
    },
    clickMe(pitem) {
      this.inboxSHOW = !this.inboxSHOW;
      this.selectedAvatar = pitem;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style lang="scss" scoped>
.fixeddiv {
  height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
}

.avatarlist {
  font-size: 16px;
  font-weight: 600;
  color: #666;
  margin-bottom: 2px;
}

.fgOwgm {
  text-align: left;
  white-space: pre-line;
  padding: 2px 8px 2px 16px;
  background-color: rgb(242, 242, 242);
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  word-break: break-word;
  line-height: 1.4;
  box-shadow: transparent 0px 0px 15px 2px;
  margin-right: 40px;
  margin-left: -10px;
  max-width: 280px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}

.fcMOyC {
  text-align: left;
  white-space: pre-line;
  padding: 2px 8px 2px 16px;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  word-break: break-word;
  line-height: 1.4;
  box-shadow: transparent 0px 0px 15px 2px;
  background-color: rgb(238, 249, 250);
  margin-left: 10px;
  max-width: 280px;
  position: absolute;
  right: 5px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}
</style>
