<template>
  <div>
    <section>
      <v-parallax
        dark
        src="https://onlineogrenim.com/assets/onlineogrenim11min.jpg"
      >
        <v-row align="center" justify="center">
          <v-col class="text-center" cols="12">
            <h1 class="  text-center col col-12">
              <span class="font-weight-bold display-2">
                WELCOME TO
              </span>
              <br />
              <span class="font-weight-thin  display-4">
                ONLINEOGRENIM
              </span>
            </h1>
          </v-col>
        </v-row>
      </v-parallax>
    </section>

    <section>
      <div>
        <v-row justify="center">
          <v-col cols="10" xs="10" sm="6">
            <form>
              <v-card elevation="18" max-width="420px" min-width="300px" class="mx-auto">
                <div class="text-center ma-auto">
                  <br />
                  <span class="body-2"
                    >Book a Demo With a Preply Enterprise Expert</span
                  >
                </div>
                <v-card-text>
                  Full name
                  <v-text-field dense outlined required></v-text-field>
                  Company name
                  <v-text-field
                    dense
                    outlined
                    hint="example of helper text only on focus"
                  ></v-text-field>
                  Corporate email
                  <v-text-field
                    dense
                    outlined
                    type="email"
                    required
                  ></v-text-field>
                  Corporate phone
                  <v-text-field
                    dense
                    outlined
                    type="tel"
                    required
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    block
                    dark
                    large
                    class="black mb-5"
                    text
                    @click="dialog = false"
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
                <v-row class="ml-3 mr-3">
                  <span class="caption"
                    >This site is protected by reCAPTCHA and the
                    <a href=""> Google Privacy Policy</a> and
                    <a href="">Terms of Service</a> apply.</span
                  >
                  <br />
                </v-row>
              </v-card>
            </form>
          </v-col>
        </v-row>
      </div>
    </section>

    <v-sparkline
      :value="value"
      :gradient="gradient"
      :smooth="radius || false"
      :padding="padding"
      :line-width="width"
      :stroke-linecap="lineCap"
      :gradient-direction="gradientDirection"
      :fill="fill"
      :type="type"
      :auto-line-width="autoLineWidth"
      auto-draw
    ></v-sparkline>
  </div>
</template>

<script>
const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "yellow"],
  ["purple", "violet"],
  ["#00c6ff", "#F0F", "#FF0"],
  ["#f72047", "#ffd200", "#1feaea"]
];

export default {
  data: () => ({
    
    width: 2,
    radius: 10,
    padding: 8,
    lineCap: "round",
    gradient: gradients[5],
    value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
    gradientDirection: "top",
    gradients,
    fill: false,
    type: "trend",
    autoLineWidth: false
  })
};
</script>
