<template>
  <div class="grey lighten-3">
    <v-container>
      <v-card class="mt-4">
        <v-row class="hidden-sm-and-down">
          <v-col>
            <v-expansion-panels flat>
              <v-expansion-panel flat>
                <v-expansion-panel-header>
                  <span class="headline font-weight-bold">
                    Math tutors & teachers online 224 tutors available
                  </span>
                  <span class="blue--text">Read more</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  Every person trying to learn Math has a vision of what success
                  looks like when communicating in a professional.. Math tutors
                  & teachers online14926 environment, for academic success or
                  with friends and family. Achieve amazing results with one of
                  the top Math teachers rated 4.9 points out of 5 from all over
                  the world it’s really easy to find one to match your learning
                  preferences and fit your busy schedule. Nobody understands
                  students better than a personal tutor and it’s not that
                  expensive to find a tutor online. Why choose the 1-on-1 Math
                  class format? At your first class prepare to have a
                  conversation about what you are using Math for and about the
                  goals you are hoping to achieve. The teacher will listen to
                  the way you speak to note what you are doing well, to find the
                  problem areas and create a customized learning plan. Select a
                  teacher who’s dedicated to helping each student individually.
                  Just pick a teacher whose teaching style you might like and
                  schedule a trial class for a stimulating conversation in Math
                  now!
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <span> </span>
          </v-col>
        </v-row>

        <v-form>
          <v-row wrap class="mx-auto">
            <v-col cols="12" xs="12" sm="12" md="12" lg="5">
              <v-text-field
                v-model="search"
                color="grey darken-2"
                outlined
                clearable
                label="Aradığınız kurs ya da öğretmen..."
                type="text"
                hint="Matematik ya da Murat Hoca ya da LGS YKS..."
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="4" md="4" lg="2">
              <v-btn
                outlined
                style="height:55px;width:100%"
                @click="
                  setRange[0] = range[0];
                  setRange[1] = range[1];
                  dialog = true;
                "
                ><span style="font-size:15px"
                  >{{ range[0] }} TL - {{ range[1] }} TL
                  <v-icon v-if="dialog">mdi-menu-up</v-icon>
                  <v-icon v-else>mdi-menu-down</v-icon></span
                >
              </v-btn>

              <v-dialog loading="true" v-model="dialogMessage" max-width="390">
                <v-card height="330px">
                  <v-card-title class="headline">
                    <span>Send Message</span>
                  </v-card-title>
                  <v-card-text>
                    <v-textarea
                      class="mt-3"
                      outlined
                      name="input-7-4"
                      label=""
                      value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                    ></v-textarea>
                  </v-card-text>
                  <v-card-actions>
                    <v-row>
                      <v-btn class="ml-5" text @click="dialogMessage = false">
                        Cancel
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="mr-5"
                        color="indigo darken-4"
                        text
                        @click="
                          dialogLoad = true;
                          dialogMessage = false;
                        "
                      >
                        Tamam
                      </v-btn>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                loading="true"
                v-model="dialogVideo"
                max-width="500"
                width="100%"
              >
                <v-card height="420px">
                  <v-card-text>
                    <v-row class="ma-auto">
                      <v-spacer></v-spacer>
                      <v-btn icon fab @click="dialogVideo = false">
                        <v-icon>mdi-close</v-icon></v-btn
                      >
                    </v-row>
                    <iframe
                      id="divScope"
                      class="youtube-video"
                      width="100%"
                      height="300"
                      src="https://www.youtube.com/embed/dip_8dmrcaU"
                      rel="0"
                      showinfo="0"
                      frameborder="0"
                      enablejsapi="1"
                      allow="autoplay; encrypted-media;enablejsapi=1"
                      allowfullscreen
                    ></iframe>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog loading="true" v-model="dialog" max-width="390">
                <v-card height="210px">
                  <v-card-title class="headline">
                    <br />
                  </v-card-title>
                  <v-card-text>
                    <v-range-slider
                      v-model="setRange"
                      :max="max"
                      :min="min"
                      hide-details
                      class="align-center"
                      thumb-label="always"
                      track-color="green"
                      color="green darken-2"
                    >
                      <template v-slot:prepend>
                        <v-text-field
                          :value="setRange[0]"
                          class="mt-0 pt-0"
                          hide-details
                          single-line
                          type="number"
                          style="width: 50px"
                          @change="$set(setRange, 0, $event)"
                        ></v-text-field>
                      </template>
                      <template v-slot:append>
                        <v-text-field
                          :value="setRange[1]"
                          class="mt-0 pt-0"
                          hide-details
                          single-line
                          type="number"
                          style="width: 50px"
                          @change="$set(setRange, 1, $event)"
                        ></v-text-field>
                      </template>
                    </v-range-slider>
                  </v-card-text>
                  <v-card-actions>
                    <v-row class="mt-4">
                      <v-btn
                        class="ml-4"
                        color="grey"
                        text
                        @click="setRange = [30, 150]"
                      >
                        Reset
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="mr-4"
                        color="indigo darken-4"
                        text
                        @click="
                          range = setRange;
                          dialogLoad = true;
                          dialog = false;
                        "
                      >
                        Tamam
                      </v-btn>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col
              class="hidden-xs-only"
              cols="12"
              xs="12"
              sm="8"
              md="8"
              lg="5"
            >
              <v-autocomplete
                color="grey darken-2"
                v-model="values"
                :items="states"
                outlined
                clearable
                chips
                small-chips
                label="Categories..."
                multiple
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>

        <v-row class="pr-5">
          <v-dialog v-model="dialogLoad" hide-overlay persistent width="300">
            <v-card flat class="white" dark>
              <v-card-text>
                ........
                <v-progress-linear
                  indeterminate
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-menu class="mb-5" outlined :rounded="rounded" offset-y>
            <template v-slot:activator="{ attrs, on }">
              <a v-bind="attrs" v-on="on"
                >Sort by calories <v-icon>mdi-arrow-down</v-icon>
              </a>
            </template>
            <v-list dense>
              <v-list-item @click="orderRateMinToMax">
                <v-list-item-title>Order Min</v-list-item-title>
              </v-list-item>
              <v-list-item @click="orderRateMaxToMin">
                <v-list-item-title>Order Max</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
        <v-card-actions>
          <v-row v-if="0" style="margin-top:-50px; ">
            <v-col>
              <v-expansion-panels v-model="panel" flat class="grey lighten-4">
                <v-expansion-panel>
                  <v-expansion-panel-header
                    ><span style="font-size:16px" class="font-weight-light"
                      >Daha fazla arama kriteri ekle
                      <v-btn icon>
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn></span
                    >
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="mt-4" align="center">
                      <v-col xs="12" sm="3">
                        <v-range-slider
                          class="mt-2"
                          :max="maxAge"
                          :min="18"
                          v-model="rangeAge"
                          :label="ex4.label"
                          :thumb-color="ex4.color"
                          thumb-label="always"
                          :thumb-size="24"
                          color="grey"
                          track-color="blue"
                        ></v-range-slider>
                      </v-col>
                      <v-col cols="2" xs="12" sm="6" class="mb-4">
                        <v-switch
                          @click="female = null"
                          v-model="male"
                          label="Male"
                          color="blue"
                          value="male"
                          hide-details
                        ></v-switch>
                        <v-switch
                          @click="male = null"
                          v-model="female"
                          label="Female"
                          color="red"
                          value="female"
                          hide-details
                        ></v-switch>
                      </v-col>
                      <v-btn dark class="green" @click.stop="panel = 1"
                        >Tamam</v-btn
                      >
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>

    <v-container>
      <v-subheader v-if="0">
        Matematik Rate perhour{{ rangeTL }} Age: {{ rangeAge }}
      </v-subheader>
      <span class="ml-2 mr-1 body-2 font-weight-light">Kayıt sayısı:</span
      ><span class="body-2 font-weight-light">{{ searching.length }} </span>
      <v-row v-if="0">
        <v-col class="ml-3">
          <v-btn @click.prevent="SHOWLIST = 1" icon
            ><v-icon>mdi-format-list-bulleted</v-icon></v-btn
          ><v-btn @click.prevent="SHOWLIST = 0" icon
            ><v-icon>mdi-apps</v-icon></v-btn
          >
          <span small>({{ searching.length }}) </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          v-for="item in searching"
          :key="item.name"
          xs="12"
          sm="12"
          lg="12"
          xl="8"
        >
          <v-card round outlined>
            <div class="d-md-flex  justify-space-between">
              <div class="d-flex  justify-space-between">
                <v-card>
                  <v-avatar
                    class="ml-3 mt-5 hidden-sm-and-down"
                    size="145"
                    tile
                  >
                    <v-img
                      src="https://cdn.vuetifyjs.com/images/cards/forest-art.jpg"
                    >
                      <v-sheet
                        v-if="item.calories == 56"
                        color="light-green accent-3"
                        height="20"
                        width="100%"
                        ><span
                          class="white--text font-weight-bold body-2 ma-auto"
                          >Top tutor</span
                        ></v-sheet
                      >
                    </v-img>
                  </v-avatar>
                  <v-card-actions class="hidden-sm-and-down">
                    <v-list two-line>
                      <v-list-item>
                        <v-spacer></v-spacer>
                        <v-btn @click="dialogVideo = true" outlined>
                          <v-icon>mdi-video</v-icon> video</v-btn
                        >
                      </v-list-item>
                      <v-list-item v-if="0">
                        <v-sheet
                          color="green lighten-3"
                          height="10"
                          width="18"
                        ></v-sheet>
                        <v-sheet color="green" height="10" width="18"></v-sheet>
                        <v-sheet
                          color="green lighten-2"
                          height="10"
                          width="18"
                        ></v-sheet>
                        <v-sheet
                          color="green lighten-1"
                          height="10"
                          width="18"
                        ></v-sheet>
                        <v-sheet
                          color="green darken-2"
                          height="10"
                          width="18"
                        ></v-sheet>
                        <v-sheet
                          color="green lighten-2"
                          height="10"
                          width="18"
                        ></v-sheet>
                        <v-sheet
                          color="green lighten-2"
                          height="10"
                          width="18"
                        ></v-sheet>
                      </v-list-item>
                    </v-list>
                  </v-card-actions>
                </v-card>

                <v-card-title>
                  <v-row cols class="mt-1">
                    <v-avatar class="ml-4 hidden-md-and-up" size="85" tile>
                      <v-img
                        src="https://cdn.vuetifyjs.com/images/cards/forest-art.jpg"
                      ></v-img>
                    </v-avatar>

                    <div class="ml-5" style="line-height: 1.7em;">
                      <v-list-item>
                        <v-row align="center">
                          <span style="font-size:20px">{{ item.name }}</span>
                          <div class="ml-4 mr-1">
                            <v-img
                              width="17"
                              height="13"
                              src="https://seeklogo.com/images/T/turk-bayragi-flag-of-turkey-logo-BD14DB50D9-seeklogo.com.png"
                            ></v-img>
                          </div>
                          <v-icon size="18" color="indigo darken-4 lighten-2"
                            >mdi-shield-check</v-icon
                          >
                        </v-row>
                      </v-list-item>

                      <v-list-item-subtitle align="top">
                        <v-icon size="18">mdi-school</v-icon> Ellie
                        Goulding</v-list-item-subtitle
                      >
                      <v-list-item-subtitle>
                        <v-icon size="20">mdi-account</v-icon> 10 active
                        students - 34 lessons</v-list-item-subtitle
                      >
                      <v-list-item-subtitle class="hidden-xs-only">
                        Speaks: Math
                        <v-chip class="green lighten-5" small>Native</v-chip>
                        Italian B2 Spanish
                        <v-chip class="cyan lighten-5" small>B2</v-chip>
                      </v-list-item-subtitle>
                    </div>
                  </v-row>

                  <v-row justify="space-between" class="mt-4">
                    <v-list-item class="grow hidden-md-and-up">
                      <v-row align="center">
                        <v-icon class="ml-4" color="orange" size="18"
                          >mdi-star</v-icon
                        >
                        <span class="title">4.5</span>
                        <span class="primary--text subtitle-2 ml-2"
                          >64 Reviews</span
                        >
                        <v-col offset="2">
                          <span class="  subheading"
                            >{{ item.calories }} TL</span
                          >
                        </v-col>
                      </v-row>
                    </v-list-item>

                    <div class="mt-2 ml-5 tile mr-5">
                      <span class=" font-weight-light ">
                        <p style="line-height: 1.7em; font-size:13px">
                          Certified Math Teacher with over 10 years experience I
                          have been teaching for over a decade mainly
                          specializing in Business Math and Exam Preparation. I
                          love seeing my students achieve their goals whether it
                          is simply being more comfortable in social situations
                          to giving professional presentations. I teach all
                          levels and abilities and am focused on helping you
                          achieve your Math goals.
                        </p>
                      </span>
                    </div>
                  </v-row>
                </v-card-title>
              </div>
              <v-card-text>
                <v-row class="mt-1 hidden-sm-and-down" justify="center">
                  <v-col offset-sm="1">
                    <v-list-item-title>
                      <v-icon color="orange" size="18">mdi-star</v-icon>
                      <span class="title">4.5</span>
                    </v-list-item-title>
                    <v-list-item-subtitle class="caption"
                      >24 reviews</v-list-item-subtitle
                    >
                  </v-col>
                  <v-col class="mr-4">
                    <v-list-item-title class="title">
                      <span>{{ item.calories }} TL</span></v-list-item-title
                    >
                    <v-list-item-subtitle class="caption"
                      >per hour</v-list-item-subtitle
                    >
                  </v-col>
                </v-row>
                <div class="hidden-sm-and-down"><br /></div>
                <v-row cols>
                  <v-col>
                    <v-btn
                      class="indigo darken-4"
                      block
                      outlined
                      dark
                      large
                      @click="reserve"
                      to="/detay"
                      target="_blank"
                    >
                      <v-icon size="20" class="mr-2"
                        >mdi-bookmark-outline</v-icon
                      ><span>Book trial lesson</span></v-btn
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      color="indigo darken-4"
                      block
                      outlined
                      large
                      @click="dialogMessage = true"
                    >
                      <v-icon size="20" class="mr-2">mdi-email-outline</v-icon>
                      <span>Message</span></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    rounded: true,
    values: [],
    dialogVideo: false,
    dialogMessage: false,
    dialogLoad: false,
    setRange: [30, 150],
    dialog: false,
    search: "",
    panel: 1,
    female: "",
    male: "",
    rangeTL: "",
    sliderTL: false,
    types: ["Places to Be", "Places to See"],
    cards: ["Good", "Best", "Finest"],
    socials: [
      {
        icon: "mdi-facebook",
        color: "indigo"
      },
      {
        icon: "mdi-linkedin",
        color: "cyan darken-1"
      },
      {
        icon: "mdi-instagram",
        color: "red lighten-3"
      }
    ],

    minAge: 0,
    maxAge: 100,
    rangeAge: [20, 60],
    ex4: { label: "Yaş", val: 50, color: "grey" },
    min: 30,
    max: 150,
    range: [30, 150],
    rangeORG: [30, 150],
    ex3: { label: "Saat ücreti (TL)", val: 50, color: "green" },
    rating: 4,
    e6: [],
    states: [
      "Alabama",
      "Alaska",
      "American Samoa",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Federated States of Micronesia",
      "Florida",
      "Georgia",
      "Guam",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Marshall Islands",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Northern Mariana Islands",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Palau",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virgin Island",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming"
    ],
    SHOWLIST: true,
    courses: [
      {
        name: "Frozen Yogurt",
        calories: 150
      },
      {
        name: "Ice cream sandwich",
        calories: 137
      },
      {
        name: "Eclair",
        calories: 96
      },
      {
        name: "Cupcake",
        calories: 30
      },
      {
        name: "Gingerbread",
        calories: 56
      },
      {
        name: "Jelly bean",
        calories: 105
      },
      {
        name: "Lollipop",
        calories: 102
      },
      {
        name: "Honeycomb",
        calories: 108
      },
      {
        name: "Donut",
        calories: 52
      },
      {
        name: "KitKat",
        calories: 78
      },
      {
        name: "Frozen Yogurt1",
        calories: 150
      },
      {
        name: "Ice cream sandwich2",
        calories: 137
      },
      {
        name: "Eclair2",
        calories: 96
      },
      {
        name: "Cupcake3",
        calories: 30
      },
      {
        name: "Gingerbread4",
        calories: 56
      },
      {
        name: "Jelly bean4",
        calories: 105
      },
      {
        name: "Lollipop4",
        calories: 102
      },
      {
        name: "Honeycomb32",
        calories: 108
      },
      {
        name: "Donut23",
        calories: 52
      },
      {
        name: "KitKat2",
        calories: 78
      },
      {
        name: "Frozen Yogurta",
        calories: 150
      },
      {
        name: "Ice cream sandwicha",
        calories: 137
      },
      {
        name: "Eclaira",
        calories: 96
      },
      {
        name: "Cupcakae",
        calories: 30
      },
      {
        name: "Gingerbreada",
        calories: 56
      },
      {
        name: "Jelly beana",
        calories: 105
      },
      {
        name: "Lollipopa",
        calories: 102
      },
      {
        name: "Honeycomba",
        calories: 108
      },
      {
        name: "Donuts",
        calories: 52
      },
      {
        name: "KitKatss",
        calories: 78
      },
      {
        name: "Frozen Yogurt1s",
        calories: 150
      },
      {
        name: "Ice cream sandwich2d",
        calories: 137
      },
      {
        name: "Eclair2fd",
        calories: 96
      },
      {
        name: "Cupcake3d",
        calories: 30
      },
      {
        name: "Gingerbread4d",
        calories: 56
      },
      {
        name: "Jelly bean4d",
        calories: 105
      },
      {
        name: "Lollipop4d",
        calories: 102
      },
      {
        name: "Honeycomb32d",
        calories: 108
      },
      {
        name: "Donut23d",
        calories: 52
      },
      {
        name: "KitKat2d",
        calories: 78
      },
      {
        name: "Frozen Yogurtaz",
        calories: 150
      },
      {
        name: "Ice cream sandwichaz",
        calories: 137
      },
      {
        name: "Eclairaz",
        calories: 96
      },
      {
        name: "Cupcakeza",
        calories: 30
      },
      {
        name: "Gingerbreadaz",
        calories: 56
      },
      {
        name: "Jelly beanaz",
        calories: 105
      },
      {
        name: "Lollipopaz",
        calories: 102
      },
      {
        name: "Honeycombza",
        calories: 108
      },
      {
        name: "Donutza",
        calories: 52
      },
      {
        name: "KitKatza",
        calories: 78
      },
      {
        name: "Frozen Yogurtza1",
        calories: 150
      },
      {
        name: "Ice cream sazandwich2",
        calories: 137
      },
      {
        name: "Eclzaair2",
        calories: 96
      },
      {
        name: "Cupcazake3",
        calories: 30
      },
      {
        name: "Gingerazbread4",
        calories: 56
      },
      {
        name: "Jelly bazean4",
        calories: 105
      },
      {
        name: "Lollipoazp4",
        calories: 102
      },
      {
        name: "Honeycoazmb32",
        calories: 108
      },
      {
        name: "Donuzat23",
        calories: 52
      },
      {
        name: "KitKaazt2",
        calories: 78
      },
      {
        name: "Frozen Yoazgurta",
        calories: 150
      },
      {
        name: "Ice creazam sandwicha",
        calories: 137
      },
      {
        name: "Eclaiazara",
        calories: 96
      },
      {
        name: "Cupcazakae",
        calories: 30
      },
      {
        name: "Gingerbazreada",
        calories: 56
      },
      {
        name: "Jelly bazeana",
        calories: 105
      },
      {
        name: "Lollipoazpa",
        calories: 102
      },
      {
        name: "Honeycozamba",
        calories: 108
      },
      {
        name: "Donuazts",
        calories: 52
      },
      {
        name: "KitKaaztss",
        calories: 78
      },
      {
        name: "Frozen Yogzaurt1s",
        calories: 150
      },
      {
        name: "Ice cream sandwiazch2d",
        calories: 137
      },
      {
        name: "Eclairaz2fd",
        calories: 96
      },
      {
        name: "Cupcakeaza3d",
        calories: 30
      },
      {
        name: "Gingerbrazead4d",
        calories: 56
      },
      {
        name: "Jelly beanaz4d",
        calories: 105
      },
      {
        name: "Lollipoazp4d",
        calories: 102
      },
      {
        name: "Honeycomazb32d",
        calories: 108
      },
      {
        name: "Donut2az3d",
        calories: 52
      },
      {
        name: "KitKataz2d",
        calories: 78
      }
    ],

    loading: false,
    selection: 1,
    items: [
      { header: "Inbox" },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Brunch this weekend?",
        subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
        subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        title: "Oui oui",
        subtitle:
          '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?'
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
        title: "Birthday gift",
        subtitle:
          '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?'
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle:
          '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.'
      }
    ]
  }),
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    keywords() {
      if (!this.search) return [];

      const keywords = [];

      for (const search of this.searching) {
        keywords.push(search.keyword);
      }

      return keywords;
    },
    searching() {
      if (!this.search) return this.searchRate;

      const search = this.search.toLowerCase();

      return this.searchRate.filter(item => {
        const text = item.name.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
    searchRate() {
      if (this.range[0] == 30 && this.range[0] == 150) return this.courses;

      return this.courses.filter(item => {
        return item.calories >= this.range[0] && item.calories <= this.range[1];
      });
    }
  },
  methods: {
    getImage() {
      const min = 550;
      const max = 560;

      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    orderRateMinToMax() {
      this.courses.sort((a, b) => {
        return +(a.calories > b.calories) || +(a.calories === b.calories) - 1;
      });
    },
    orderRateMaxToMin() {
      this.courses.sort((a, b) => {
        return +(a.calories < b.calories) || +(a.calories === b.calories) - 1;
      });
    },
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
    clickMe() {
      this.loading = true;
      this.message = "Wait for it...";
      setTimeout(() => {
        this.loading = false;
        this.message = `You've clicked me!`;
      }, 2000);
    }
  },
  watch: {
    dialogLoad(val) {
      if (!val) return;

      setTimeout(() => (this.dialogLoad = false), 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.recommended {
  background: #50bf16;
}

.iubJdx {
  font-weight: 500;
  font-size: 18px;
}

.hKfViD {
  display: block;
  margin: 8px;
  background-color: white;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  color: inherit;
}
.body1 {
  position: relative;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}

.body1 {
  margin: 0px;
  padding: 0px;
  font-family: Roboto, -apple-system, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  color: rgb(60, 68, 71);
  background: rgb(233, 235, 235);
  box-sizing: border-box;
}
</style>
