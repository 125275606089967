<template>
  <div class="grey lighten-3">
    <v-container>
      <v-row>
        <v-col offset-md="1" sm="6">
          <br /><br />
          <h3 class="ml-5">Login</h3>
          <br />
          <form @submit.prevent="pressed">
            <div class="email">
              <input type="email" v-model="email" placeholder="email" />
            </div>
            <div class="password">
              <input
                type="password"
                v-model="password"
                placeholder="password"
              />
            </div>

            <button type="submit" class="button">Login</button>
          </form>
          <br />
          <div class="error" v-if="error">{{ error.message }}</div>

          <span class="ml-4"
            >Need an account? Click here
            <router-link to="/register">Register</router-link>
          </span>
          <br />
          <br />
          <br />
          <v-btn
            class="mt-2 ml-5"
            outlined
            large
            fab
            color="red"
            @click.prevent="signInWithGoogle"
          >
            <v-icon>mdi-google</v-icon>
          </v-btn>
          <v-btn
            class="mt-2 ml-5"
            outlined
            large
            fab
            color="blue"
            @click.prevent="signInWithFacebook"
          >
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
export default {
  data() {
    return {
      email: "",
      password: "",
      error: ""
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    async pressed() {
      await firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(u => {
          console.log(u);
          this.error = { message: "User LogedIn" };
          this.$router.replace({ name: "secret" });
        })
        .catch(error => {
          this.error = error;
        });
    },

    signInWithGoogle() {
      const obj = {};
      obj.online = true;

      var provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(u => {
          console.log(u);
          this.error = { message: "Google user  LogedIn" };
          this.$router.replace({ name: "secret" });
        })
        .catch(error => {
          this.error = error;
        });
    },
    signInWithFacebook() {
      const obj = {};
      obj.online = true;

      var provider = new firebase.auth.FacebookAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(u => {
          console.log(u);
          this.error = { message: "Google user  LogedIn" };
          this.$router.replace({ name: "about" });
        })
        .catch(error => {
          this.error = error;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.error {
  color: rgb(245, 239, 244);
  font-size: 16px;
}
input {
  width: 400px;
  padding: 20px;
  margin: 10px;
  font-size: 21px;
  border: 1px solid grey;
}

.button {
  margin-top: 20px;
  margin-left: 10px;
  width: 400px;
  height: 75px;
  font-size: 150%;
  background: #6e6e6a;
  color: lightgrey;
  border: 1px solid grey;
}
</style>
