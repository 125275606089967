<template>
  <div>    
    <div>
      <button class="caption" v-if="loggedIn" @click="signOut">Sign Out</button>
      <v-btn class="caption" v-if="!loggedIn"  to="login" >Login</v-btn>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  data() {
    return {
      loggedIn: false
    };
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      this.loggedIn = !!user;
      // if(user){
      //     this.loggedIn=true;
      //   } else {
      // this.loggedIn=false;
      //   }
    });
  },
  methods: {
    async signOut() {
      try {
        const data = await firebase.auth().signOut();
        console.log(data);
        this.$router.replace({ name: "login" });
      } catch (error) {
        this.error = error;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
