<template>
  <div>
    <h1>Profile</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
