<template>
  <div>
    <v-container>
      <br /><br />
      <div class="v-card--material mt-4 v-card v-sheet theme--light">
        <div class="v-card__title align-start">
          <div
            class="overflow-hidden mt-n9 transition-swing v-card--material__sheet v-sheet theme--light elevation-6 rounded  green"
            style="max-width: 100%;"
          >
            <div class="pa-7">
              <i
                aria-hidden="true"
                class="v-icon notranslate mdi mdi-clipboard-text theme--dark"
              ></i>
            </div>
          </div>
          <div class="text-h5 v-card--material__title pl-3">
            Invoices
            <div class="text-subtitle-1 mb-n4"><!----></div>
          </div>
        </div>
        <div class="v-data-table theme--light">
          <div class="v-data-table__wrapper">
            <table>
              <thead>
                <tr>
                  <th class="primary--text">ID</th>
                  <th class="primary--text">Name</th>
                  <th class="primary--text">Country</th>
                  <th class="primary--text">City</th>
                  <th class="text-right primary--text">Salary</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Dakota Rice</td>
                  <td>Niger</td>
                  <td>Oud-Turnhout</td>
                  <td class="text-right">$36,738</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Minverva Hooper</td>
                  <td>Curaçao</td>
                  <td>Sinaas-Waas</td>
                  <td class="text-right">$23,789</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Sage Rodriguez</td>
                  <td>Netherlands</td>
                  <td>Baileux</td>
                  <td class="text-right">$56,142</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Philip Chaney</td>
                  <td>Korea, South</td>
                  <td>Overland Park</td>
                  <td class="text-right">$38,735</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Doris Greene</td>
                  <td>Malawi</td>
                  <td>Feldkirchen in Kärnten</td>
                  <td class="text-right">$63,542</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Mason Porter</td>
                  <td>Chile</td>
                  <td>Gloucester</td>
                  <td class="text-right">$78,615</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!---->
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      desserts: [
        {
          name: "Frozen Yogurt",
          calories: 159
        },
        {
          name: "Ice cream sandwich",
          calories: 237
        },
        {
          name: "Eclair",
          calories: 262
        },
        {
          name: "Cupcake",
          calories: 305
        },
        {
          name: "Gingerbread",
          calories: 356
        },
        {
          name: "Jelly bean",
          calories: 375
        },
        {
          name: "Lollipop",
          calories: 392
        },
        {
          name: "Honeycomb",
          calories: 408
        },
        {
          name: "Donut",
          calories: 452
        },
        {
          name: "KitKat",
          calories: 518
        }
      ]
    };
  }
};
</script>
