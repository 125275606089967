<template>
  <div>
    <v-app-bar flat dense color="grey lighten-1" class="hidden-lg-and-up">
      <div v-show="!inboxSHOW || windowSize.x > 950" class=" ml-5">
        <span class=" font-weight-bold">{{ selectedAvatar.title }}</span>
      </div>
    </v-app-bar>

    <v-card flat>
      <v-footer bottom fixed class="hidden-md-and-up">
        <v-text-field
          class="input-group--focused"
          outlined
          append-icon="mdi-send"
          clearable
          v-model="message"
          placeholder="Type your message here...."
          @click:append="sendMessage"
        ></v-text-field> </v-footer
    ></v-card>
    <v-row v-resize="onResize" v-show="0"> </v-row>

    <v-row class="mt-1">
      <v-col cols="12" xs="12" sm="12" md="6" lg="3">
        <div class="avatarlist" v-show="inboxSHOW || windowSize.x > 950">
          <v-list three-line>
            <v-list-item-group v-model="selected" active-class="grey--text">
              <template v-for="(item, index) in items">
                <v-list-item :key="item.title" @click.prevent="clickMe(item)">
                  <v-list-item-avatar size="50">
                    <v-img :src="item.avatar"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="body-2 font-weight-bold"
                      v-html="item.title"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      class="font-weight-light"
                      v-html="item.subtitle"
                      >{{ index }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-avatar v-show="item.msgNo > 0" color="red" size="16">
                      <span
                        class="white--text caption font-weight-bold mx-auto"
                        >{{ item.msgNo }}</span
                      >
                    </v-avatar>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </div>
      </v-col>

      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="9"
        v-show="!inboxSHOW || windowSize.x > 950"
      >
        <v-container style="height: 400px;" v-show="!selectedAvatar.title">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="subtitle-1 text-center" cols="12">
              Select a tutor to start a conversation
            </v-col>
            <v-col cols="3">
              <v-progress-linear
                color="grey"
                indeterminate
                rounded
                height="1"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>

        <v-row v-show="selectedAvatar.title">
          <v-col xs="12" sm="12" md="12" lg="7">
            <v-card flat scrollable class="pa-2 fixeddiv mx-auto">
              <v-responsive
                id="hide-on-scroll-example"
                class=" overflow-y-auto"
              >
                <v-responsive>
                  <v-list one-line>
                    <template v-for="(item, index) in messages">
                      <v-list-item class="mb-3" :key="item.title">
                        <v-list-item-avatar size="32">
                          <v-img :src="selectedAvatar.avatar"></v-img>
                        </v-list-item-avatar>
                        <div class="fgOwgm">
                          <v-list-item-content>
                            <span>{{ item.subtitle }}</span>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text
                              v-text="item.action"
                            ></v-list-item-action-text>
                          </v-list-item-action>
                        </div>
                      </v-list-item>
                      <v-divider v-show="0" :key="index"></v-divider>
                    </template>
                  </v-list>
                  <v-list one-line class="mb-3">
                    <template v-for="(item, index) in messages">
                      <v-list-item class="mt-4" :key="item.title">
                        <div class="fcMOyC">
                          <v-list-item-content>
                            <span>{{ item.subtitle }}{{ index }}</span>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text
                              v-text="item.action"
                            ></v-list-item-action-text>
                          </v-list-item-action>
                        </div>
                      </v-list-item>
                      <v-divider v-show="0" :key="index"></v-divider>
                    </template>
                  </v-list>
                  <br />
                </v-responsive>
              </v-responsive>
            </v-card>

            <v-row class="mt-1 hidden-sm-and-down">
              <v-col fixed horizontal scroll-target="#hide-on-scroll-example">
                <v-text-field
                  class="input-group--focused"
                  outlined
                  append-icon="mdi-send"
                  clearable
                  v-model="message"
                  placeholder="Type your message here.."
                  @click:append="sendMessage"
                ></v-text-field> </v-col
            ></v-row>
          </v-col>
          <v-col class="hidden-md-and-down" xs="12" sm="12" md="12" lg="5">
            <v-container>
              <v-row>
                <v-col>
                  <v-list subheader two-line>
                    <v-item-group>
                      <v-list-item to="detay">
                        <v-list-item-avatar>
                          <v-img :src="selectedAvatar.avatar"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="font-weight-bold"
                            v-text="selectedAvatar.title"
                          >
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <v-rating
                              readonly
                              :value="4"
                              dense
                              color="orange"
                              background-color="orange"
                              hover
                              size="14"
                              class="mr-2"
                            ></v-rating>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <span class="font-weight-bold">75 TL</span>
                          <span class="caption">per hour</span>
                        </v-list-item-action>
                      </v-list-item>
                    </v-item-group>
                  </v-list>

                  <v-list subheader three-line>
                    <v-list-item v-for="folder in folders" :key="folder.title">
                      <v-list-item-avatar size="18" color="indigo darken-4">
                        <span class="caption white--text">{{ folder.no }}</span>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          class="font-weight-bold"
                          v-text="folder.title"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="folder.subtitle"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <div v-if="selectedAvatar.msgNo == 0">
                    <v-btn block class="indigo darken-4 mt-5" dark bottom
                      >Book a trial lesson</v-btn
                    >
                  </div>
                  <div v-else>
                    <v-btn
                      outlined
                      block
                      class="indigo darken-4 mt-5"
                      dark
                      bottom
                      >Buy hours</v-btn
                    >
                    <v-btn
                      outlined
                      block
                      color="indigo darken-4"
                      class="  mt-2"
                      dark
                      bottom
                      >Enter classroom</v-btn
                    >
                    <v-btn
                      outlined
                      block
                      color="indigo darken-4"
                      class="  mt-2"
                      dark
                      bottom
                      >Post review</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <br /><br />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    message: "",
    aMEssage: {
      action: "2 min",
      headline: "",
      subtitle: "",
      title: "Messages"
    },
    folders: [
      {
        no: "1",
        subtitle: "Choose a suitable date and time for your first lesson",
        title: "Select time"
      },
      {
        no: "2",
        subtitle:
          "Make a secure online payment by PayPal or Visa/Mastercard paypal iconvisa iconmastercard",
        title: "Make a payment"
      },
      {
        no: "3",
        subtitle: "Meet your tutor for a video call in our virtual classroom",
        title: "Join lesson in the Preply classroom"
      }
    ],
    selectedAvatar: {
      avatar: "",
      title: "",
      subtitle: ""
    },
    windowSize: {
      x: 0,
      y: 0
    },
    inboxSHOW: true,
    selected: [2],
    messages: [
      {
        action: "15 min",
        headline: "Brunch this weekend?",
        subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        title: "Ali Connors"
      },
      {
        action: "2 hr",
        headline: "Summer BBQ",
        subtitle: `Wish I could come, but I'm out of town this weekend.`,
        title: "me, Scrott, Jennifer"
      },
      {
        action: "6 hr",
        headline: "Oui oui",
        subtitle: "Do you have Paris recommendations? Have you ever been?",
        title: "Sandra Adams"
      },
      {
        action: "12 hr",
        headline: "Birthday gift",
        subtitle:
          "Have any ideas about what we should get Heidi for her birthday?",
        title: "Trevor Hansen"
      },
      {
        action: "18hr",
        headline: "Recipe to try",
        subtitle:
          "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
        title: "Britta Holt"
      }
    ],
    items: [
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Brunch this weekend?",
        subtitle: ` Ali Connors  I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        msgNo: "2"
      },

      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: "Summer BBQ",
        subtitle: ` to Alex, Scott, Jennifer  Wish I could come, but I'm out of town this weekend.`,
        msgNo: "0"
      },

      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        title: "Oui oui",
        subtitle:
          " Sandra Adams Do you have Paris recommendations? Have you ever been?",
        msgNo: "1"
      },

      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
        title: "Birthday gift",
        subtitle:
          " Trevor Hansen  Have any ideas about what we should get Heidi for her birthday?",
        msgNo: "3"
      },

      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle:
          " Britta Holt  We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
        msgNo: "0"
      }
    ]
  }),
  mounted() {
    this.onResize();
  },
  methods: {
    sendMessage() {
      this.aMEssage.subtitle = this.message;
      this.messages.push(this.aMEssage);
      this.message = "";
    },
    clickMe(pitem) {
      this.inboxSHOW = !this.inboxSHOW;
      this.selectedAvatar = pitem;
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    }
  }
};
</script>

<style lang="scss" scoped>
.fixeddiv {
  height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
}

.avatarlist {
  font-size: 16px;
  font-weight: 600;
  color: #666;
  margin-bottom: 2px;
}

.fgOwgm {
  text-align: left;
  white-space: pre-line;
  padding: 2px 8px 2px 16px;
  background-color: rgb(242, 242, 242);
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  word-break: break-word;
  line-height: 1.4;
  box-shadow: transparent 0px 0px 15px 2px;
  margin-right: 40px;
  margin-left: -10px;
  max-width: 480px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}

.fcMOyC {
  margin-top: 20px;
  text-align: left;
  white-space: pre-line;
  padding: 2px 8px 2px 16px;
  border-radius: 6px;
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  word-break: break-word;
  line-height: 1.4;
  box-shadow: transparent 0px 0px 15px 2px;
  background-color: rgb(238, 249, 250);
  position: relative;
  margin-left: 50px;
  max-width: 480px;
  position: absolute;
  right: 0px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}
</style>
