<template>
  <div class="grey lighten-3">
    <v-container>
      <v-row>
        <v-col offset-md="1" sm="6">
          <br /><br />
          <h3 class="ml-5">Register</h3>
          <br />
          <div v-if="error">{{ error.message }}</div>
          <form @submit.prevent="pressed">
            <div class="email">
              <input type="email" v-model="email" placeholder="email" />
            </div>
            <div class="password">
              <input
                type="password"
                v-model="password"
                placeholder="password"
              />
            </div>

            <button type="submit" class="button">Register</button>
          </form>
          <br />
          <span class="ml-4"
            >Click here to <router-link to="/login">Login</router-link>
          </span>
          <br />
          <br />
          <br />
          <v-btn
            class="mt-2 ml-5"
            outlined
            large
            fab
            color="red"
            @click.prevent="signInWithGoogle"
          >
            <v-icon>mdi-google</v-icon>
          </v-btn>
          <v-btn
            class="mt-2 ml-5"
            outlined
            large
            fab
            color="blue"
            @click.prevent="signInWithFacebook"
          >
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  data() {
    return {
      email: "",
      password: "",
      error: ""
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    async pressed() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(u => {
          console.log(u);
          this.error = { message: "User created" };
          this.$router.replace({ name: "about" });
        })
        .catch(error => {
          this.error = error;
          switch (error.code) {
            case "auth/email-already-in-use":
              console.log(`Email address ${this.state.email} already in use.`);
              break;
            case "auth/invalid-email":
              console.log(`Email address ${this.state.email} is invalid.`);
              break;
            case "auth/operation-not-allowed":
              console.log(`Error during sign up.`);
              break;
            case "auth/weak-password":
              console.log(
                "Password is not strong enough. Add additional characters including special characters and numbers."
              );
              break;
            default:
              console.log(error.message);
              break;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.error {
  color: red;
  font-size: 16px;
}
input {
  width: 400px;
  padding: 20px;
  margin: 10px;
  font-size: 21px;
  border: 1px solid grey;
}

.button {
  margin-top: 20px;
  margin-left: 10px;
  width: 400px;
  height: 75px;
  font-size: 150%;
  background: #6e6e6a;
  color: lightgrey;
  border: 1px solid grey;
}
</style>
