<template>
  <div>
    <v-container>
      <v-row class="mb-5" justify="center">
        <v-col xs="12" sm="12" md="7">
          <v-card>
            <v-container>
              <v-tabs flat color="indigo accent-4" class="caption">
                <v-tab><span class="body-1">Account</span></v-tab>
                <v-tab><span class="body-1">Payment</span> </v-tab>
                <v-tab><span class="body-1">Calendar</span> </v-tab>
                <v-tab-item reverse-transition>
                  <v-card flat>
                    <v-card-text>
                      <v-card tile flat>
                        <v-row class="mt-5 justify-center">
                          <v-col cols="12" xs="12" sm="12" md="6">
                            <span class="title font-weight-bold"
                              >Information about you</span
                            >
                          </v-col>
                          <v-col cols="8"> </v-col>
                        </v-row>
                        <v-card-text>
                          <v-row class="mt-5">
                            <v-col class="hidden-xs-only" cols="4">
                              <v-subheader>First Name</v-subheader>
                            </v-col>
                            <v-col cols="12" sm="8">
                              <v-text-field
                                dense
                                outlined
                                v-model="firstName"
                                label="First Name"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="hidden-xs-only" cols="4">
                              <v-subheader>Last Name</v-subheader>
                            </v-col>
                            <v-col cols="12" sm="8">
                              <v-text-field
                                dense
                                outlined
                                v-model="lastName"
                                label="Last Name"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col class="hidden-xs-only" cols="4">
                              <v-subheader>Phone</v-subheader>
                            </v-col>
                            <v-col cols="12" sm="8">
                              <v-text-field
                                dense
                                prepend-inner-icon="mdi-phone grey--text text--lighten-2"
                                outlined
                                type="tel"
                                v-model="phone"
                                label="Phone"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col class="hidden-xs-only" cols="4">
                              <v-subheader>Email</v-subheader>
                            </v-col>
                            <v-col cols="12" sm="8">
                              <v-text-field
                                dense
                                prepend-inner-icon="mdi-email-outline  grey--text text--lighten-2"
                                outlined
                                type="email"
                                v-model="email"
                                label="Email"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row v-if="0">
                            <v-col class="hidden-xs-only" cols="4">
                              <v-subheader>City</v-subheader>
                            </v-col>
                            <v-col cols="12" sm="8">
                              <v-text-field
                                dense
                                outlined
                                v-model="city"
                                label="City"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row v-if="0">
                            <v-col class="hidden-xs-only" cols="4">
                              <v-subheader>Country</v-subheader>
                            </v-col>
                            <v-col cols="12" sm="8">
                              <v-autocomplete
                                dense
                                outlined
                                ref="country"
                                v-model="country"
                                :rules="[
                                  () => !!country || 'This field is required'
                                ]"
                                :items="countries"
                                label="Country"
                                placeholder="Select..."
                                required
                              ></v-autocomplete>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col class="hidden-xs-only" cols="4">
                              <v-subheader>Hour rate</v-subheader>
                            </v-col>
                            <v-col cols="12" sm="8">
                              <v-text-field
                                dense
                                outlined
                                v-model="HourRate"
                                label="Hour rate"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col class="hidden-xs-only" cols="4">
                              <v-subheader>Skype Code</v-subheader>
                            </v-col>
                            <v-col cols="12" sm="8">
                              <v-text-field
                                dense
                                prepend-inner-icon="mdi-skype blue--text"
                                outlined
                                v-model="skypeKod"
                                label="Skype Code"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <div class="text-center col col-12">
                            <v-btn class="indigo darken-4" dark
                              >Update Profile</v-btn
                            >
                          </div>
                        </v-card-actions>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item reverse-transition>
                  <v-card flat>
                    <v-card-text>
                      <v-card tile flat>
                        <v-row class="mt-5 justify-center">
                          <v-col cols="12" xs="12" sm="12" md="6">
                            <span class="title font-weight-bold"
                              >Payment history</span
                            >
                          </v-col>
                          <v-col cols="8"> </v-col>
                        </v-row>
                        <v-card-text>
                          <v-list three-line subheader>
                            <v-subheader>User Controls</v-subheader>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Content filtering</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  >Set the content filtering level to restrict
                                  apps that can be
                                  downloaded</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>Password</v-list-item-title>
                                <v-list-item-subtitle
                                  >Require password for purchase or use password
                                  to restrict purchase</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <v-divider></v-divider>
                          <v-list three-line subheader>
                            <v-subheader>General</v-subheader>
                            <v-list-item>
                              <v-list-item-action>
                                <v-checkbox
                                  v-model="notifications"
                                ></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Notifications</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  >Notify me about updates to apps or games that
                                  I downloaded</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-action>
                                <v-checkbox v-model="sound"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>Sound</v-list-item-title>
                                <v-list-item-subtitle
                                  >Auto-update apps at any time. Data charges
                                  may apply</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-action>
                                <v-checkbox v-model="widgets"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Auto-add widgets</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  >Automatically add home screen
                                  widgets</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item reverse-transition>
                  <v-card flat>
                    <v-card-text>
                      <v-card tile flat>
                        <v-row class="mt-5 justify-center">
                          <v-col cols="12" xs="12" sm="12" md="6">
                            <span class="title font-weight-bold"
                              >Takvim ayarları</span
                            >
                          </v-col>
                          <v-col cols="8"> </v-col>
                        </v-row>
                        <v-card-text>
                          <v-list three-line subheader>
                            <v-subheader>User Controls</v-subheader>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Content filtering</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  >Set the content filtering level to restrict
                                  apps that can be
                                  downloaded</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>Password</v-list-item-title>
                                <v-list-item-subtitle
                                  >Require password for purchase or use password
                                  to restrict purchase</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <v-divider></v-divider>
                          <v-list three-line subheader>
                            <v-subheader>General</v-subheader>
                            <v-list-item>
                              <v-list-item-action>
                                <v-checkbox
                                  v-model="notifications"
                                ></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Notifications</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  >Notify me about updates to apps or games that
                                  I downloaded</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-action>
                                <v-checkbox v-model="sound"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>Sound</v-list-item-title>
                                <v-list-item-subtitle
                                  >Auto-update apps at any time. Data charges
                                  may apply</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-action>
                                <v-checkbox v-model="widgets"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Auto-add widgets</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  >Automatically add home screen
                                  widgets</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      HourRate: "",
      skypeKod: "",
      vertical: "vertical",
      user: {},
      sound: "",
      notifications: "",
      widgets: "",
      checkbox: true,
      checkbox1: true,
      checkbox2: false
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      this.user = user;
    });
  }
};
</script>

<style lang="scss" scoped></style>
