<template>
  <v-container>
    <v-row class="mt-5">
      <v-col>
        <v-card class="mx-auto green lighten-5" outlined>
          <v-card-text>
            <v-list-item class="grow" style="height:100px">
              <v-list-item-content>
                <v-list-item-title
                  >Schedule your next lesson with</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>
                  <v-list-item-avatar color="grey darken-3">
                    <v-img
                      class="elevation-6"
                      alt=""
                      src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                    ></v-img> </v-list-item-avatar
                  >Evan You
                </v-list-item-title>
              </v-list-item-content>

              <v-row align="center" justify="end">
                <a @click="buyhours">
                  <v-icon class="mr-1">
                    mdi-calendar-blank
                  </v-icon>
                  <span class="subheading">Schedule lesson</span></a
                >
              </v-row>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="headline   mb-4">
                Pick up the pace!
              </div>
              <v-list-item-title class="headline mb-1">
                Time for more lessons!
              </v-list-item-title>
              <v-list-item-subtitle
                >Greyhound divisely hello coldly
                fonwderfully</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
              color="grey"
            ></v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn outlined rounded text>
              Button
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-expansion-panels popout hover>
        <v-expansion-panel
          v-for="(message, i) in messages"
          :key="i"
          hide-actions
        >
          <v-expansion-panel-header>
            <v-row align="center" class="spacer my-auto">
              <v-col cols="12" xs="12" sm="6" md="4">
                <v-row align="center" class="mx-0">
                  <v-avatar size="36px">
                    <img
                      v-if="message.avatar"
                      alt="Avatar"
                      :src="message.avatar"
                    />
                    <v-icon
                      v-else
                      :color="message.color"
                      v-text="message.icon"
                    ></v-icon>
                  </v-avatar>

                  <div
                    class="ml-4"
                    @mouseover="selectedItem = i"
                    @mouseleave="selectedItem = null"
                  >
                    <v-list-item-title class="title">
                      <span class="body-2 b" v-html="message.name"></span>
                      <span v-show="selectedItem == i">
                        <v-btn
                          class="ml-4 "
                          small
                          outlined
                          text
                          to="detay"
                          target="_blank"
                          >View profile</v-btn
                        >
                      </span>
                    </v-list-item-title>

                    <v-list-item-subtitle
                      class="body-2 font-weight-light hidden-md-and-up"
                      ><span v-html="message.title"></span> .
                      <span> {{ message.total }} TL</span>
                    </v-list-item-subtitle>
                  </div>
                  <div></div>
                </v-row>
              </v-col>

              <v-col
                class="text-no-wrap hidden-sm-and-down"
                cols="2"
                xs="2"
                sm="2"
              >
                <div justify="center">
                  <v-chip
                    v-if="message.new"
                    :color="`${message.color} lighten-4`"
                    class="ml-0 mr-2 black--text"
                    label
                    small
                  >
                    {{ message.paid }}
                  </v-chip>
                  <span v-html="message.title"></span>
                </div>
              </v-col>

              <v-col
                cols="2"
                xs="2"
                sm="2"
                v-if="message.total"
                class="ml-5 grey--text text-truncate hidden-sm-and-down"
              >
                <span> {{ message.total }} TL</span>
              </v-col>
              <v-col class="hidden-sm-and-down">
                <div class="text-center">
                  <a class="cyan--text" @click="routemessages">
                    <v-icon small color="cyan">mdi-comment-outline</v-icon>
                    <span>Messages</span>
                  </a>
                  <a class="ml-3 cyan--text" @click="buyhours" target="_blank">
                    <v-icon small color="cyan">mdi-clock</v-icon>
                    Buy hours</a
                  >
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="grey lighten-5">
            <v-divider></v-divider>
            <v-card-text>
              <v-simple-table class="grey lighten-4">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Date</th>
                      <th class="text-left">Lesson status</th>
                      <th class="text-left hidden-sm-and-down">Duration</th>
                      <th class="text-left hidden-sm-and-down">Paid</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in message.messages"
                      :key="item.date"
                      class="mb-3"
                    >
                      <td>{{ item.date }}</td>
                      <td class="text-center">
                        <v-row align="center">
                          <div>
                            <span class="caption mr-2"
                              >{{ item.lessonstatus }}
                            </span>
                          </div>
                          <v-rating
                            :value="4.5"
                            color="amber"
                            dense
                            :length="item.rating"
                            half-increments
                            readonly
                            size="16"
                          ></v-rating>
                        </v-row>
                      </td>
                      <td class="hidden-sm-and-down">{{ item.duration }}</td>
                      <td class="hidden-sm-and-down">{{ item.paid }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      selectedItem: null,
      items: [
        { text: "Real-Time", icon: "mdi-clock" },
        { text: "Audience", icon: "mdi-account" },
        { text: "Conversions", icon: "mdi-flag" }
      ],
      viewprofile: false,
      messages: [
        {
          avatar: "https://avatars0.githubusercontent.com/u/9064066?v=4&s=460",
          name: "John Leider",
          title: "No prepaid lessons",
          excerpt: "Thank you for joining our community...",
          total: "120",
          messages: [
            {
              date: "We, 20 Jan",
              lessonstatus: "Planned",
              rating: "0",
              duration: "60 min",
              paid: "60TL"
            },
            {
              date: "Fri, 11 Jan",
              lessonstatus: "Confirmed",
              rating: "5",
              duration: "60 min",
              paid: "60TL"
            },
            {
              date: "Tue, 05 Jan",
              lessonstatus: "Confirmed",
              rating: "5",
              duration: "60 min",
              paid: "60TL"
            }
          ]
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          name: "Suzan Legar",
          title: "No prepaid lessons",
          excerpt: "Thank you for joining our community...",
          total: "420",
          messages: [
            {
              date: "We, 20 Jan",
              lessonstatus: "Confirmed",
              rating: "5",
              duration: "60 min",
              paid: "60TL"
            }
          ]
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          name: "Maria Buden",
          title: "No prepaid lessons",
          excerpt: "Thank you for joining our community...",
          total: "80",
          messages: [
            {
              date: "We, 20 Jan",
              lessonstatus: "Confirmed",
              rating: "5",
              duration: "60 min",
              paid: "60TL"
            }
          ]
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
          name: "Fenuaque Dureop",
          title: "No prepaid lessons",
          excerpt: "Thank you for joining our community...",
          total: "520",
          messages: [
            {
              date: "We, 20 Jan",
              lessonstatus: "Confirmed",
              rating: "5",
              duration: "60 min",
              paid: "60TL"
            }
          ]
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          name: "Bender Drump",
          title: "No prepaid lessons",
          excerpt: "Thank you for joining our community...",
          total: "60",
          messages: [
            {
              date: "We, 20 Jan",
              lessonstatus: "Confirmed",
              rating: "5",
              duration: "60 min",
              paid: "60TL"
            }
          ]
        }
      ],
      lorem:
        "Lorem ipsum dolor sit amet, at aliquam vivendum vel, everti delicatissimi cu eos. Dico iuvaret debitis mel an, et cum zril menandri. Eum in consul legimus accusam. Ea dico abhorreant duo, quo illum minimum incorrupte no, nostro voluptaria sea eu. Suas eligendi ius at, at nemore equidem est. Sed in error hendrerit, in consul constituam cum."
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    routemessages() {
      this.$router.replace({ name: "avatars" });
    },
    buyhours() {
      this.$router.replace({ name: "detay" });
    }
  }
};
</script>

<style>
.b:hover {
  -webkit-text-fill-color: rgb(156, 152, 152);
}
</style>
