<template>
  <div class="grey lighten-3">
    <v-container fluid>
      <v-row class="mb-5" justify="center">
        <v-col xs="12" sm="12" md="7">
          <v-stepper v-model="e1">
            <v-stepper-header dense class="indigo darken-4">
              <v-stepper-step dark :complete="e1 > 1" step="1">
                <span class=" white--text">About</span>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2">
                <span class=" white--text">Description</span>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">
                <span class=" white--text">Availibility</span>
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card flat class="mb-5">
                  <v-card-text>
                    <div class="d-flex justify-center">
                      <span class="title font-weight-bold"
                        >Information about you</span
                      >
                    </div>

                    <v-row class="mt-5">
                      <v-col class="hidden-xs-only" cols="4">
                        <v-subheader>First Name</v-subheader>
                      </v-col>
                      <v-col cols="12" sm="8">
                        <v-text-field
                          dense
                          outlined
                          v-model="firstName"
                          label="First Name"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="hidden-xs-only" cols="4">
                        <v-subheader>Last Name</v-subheader>
                      </v-col>
                      <v-col cols="12" sm="8">
                        <v-text-field
                          dense
                          outlined
                          v-model="lastName"
                          label="Last Name"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="hidden-xs-only" cols="4">
                        <v-subheader>Phone</v-subheader>
                      </v-col>
                      <v-col cols="12" sm="8">
                        <v-text-field
                          dense
                          prepend-inner-icon="mdi-phone grey--text text--lighten-2"
                          outlined
                          type="tel"
                          v-model="phone"
                          label="Phone"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="hidden-xs-only" cols="4">
                        <v-subheader>Email</v-subheader>
                      </v-col>
                      <v-col cols="12" sm="8">
                        <v-text-field
                          dense
                          prepend-inner-icon="mdi-email-outline  grey--text text--lighten-2"
                          outlined
                          type="email"
                          v-model="email"
                          label="Email"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row v-if="0">
                      <v-col class="hidden-xs-only" cols="4">
                        <v-subheader>City</v-subheader>
                      </v-col>
                      <v-col cols="12" sm="8">
                        <v-text-field
                          dense
                          outlined
                          v-model="city"
                          label="City"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row v-if="0">
                      <v-col class="hidden-xs-only" cols="4">
                        <v-subheader>Country</v-subheader>
                      </v-col>
                      <v-col cols="12" sm="8">
                        <v-autocomplete
                          dense
                          outlined
                          ref="country"
                          v-model="country"
                          :rules="[() => !!country || 'This field is required']"
                          :items="countries"
                          label="Country"
                          placeholder="Select..."
                          required
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="hidden-xs-only" cols="4">
                        <v-subheader>Hour rate</v-subheader>
                      </v-col>
                      <v-col cols="12" sm="8">
                        <v-text-field
                          dense
                          outlined
                          v-model="HourRate"
                          label="Hour rate"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="hidden-xs-only" cols="4">
                        <v-subheader>Skype Code</v-subheader>
                      </v-col>
                      <v-col cols="12" sm="8">
                        <v-text-field
                          dense
                          prepend-inner-icon="mdi-skype blue--text"
                          outlined
                          v-model="skypeKod"
                          label="Skype Code"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-row class="mb-3">
                  <v-btn class="ml-5" @click="$router.go(-1)">
                    <v-icon class="mr-2">mdi-close</v-icon> Cancel
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mr-5"
                    dark
                    color="indigo darken-4"
                    @click="e1 = 2"
                  >
                    Continue
                    <v-icon class="ml-2">mdi-arrow-right-bold-outline</v-icon>
                  </v-btn>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card flat class="mb-6 ">
                  <v-toolbar flat>
                    <v-toolbar-title>Profile description</v-toolbar-title>
                  </v-toolbar>
                  <v-row>
                    <v-col class="ml-5">
                      <span class="caption grey--text">
                        Update or create a new profile headline and description.
                        İt will appear on your tutor card on the "Find tutors"
                        page.</span
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="ml-5">
                      <span class="subheading"
                        >Description for English speaking students</span
                      >
                    </v-col>
                  </v-row>

                  <v-card-text>
                    <v-text-field
                      dense
                      outlined
                      label="Subject"
                      placeholder="Write your headline in English"
                      hint='Good example:"Certified tutor with 5 year experience"'
                      persistent-hint
                    ></v-text-field>
                    <br />
                    <v-textarea
                      dense
                      outlined
                      label="About the tutor"
                      hint="400 characters minimum, 0 characters currently"
                      persistent-hint
                      counter="400"
                    ></v-textarea>
                  </v-card-text>
                </v-card>

                <v-row class="mb-3">
                  <v-btn class="ml-5" @click="e1 = 1">
                    <v-icon class="mr-2">mdi-arrow-left-bold-outline</v-icon>
                    Back
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mr-5"
                    dark
                    color="indigo darken-4"
                    @click="e1 = 3"
                  >
                    Continue
                    <v-icon class="ml-2">mdi-arrow-right-bold-outline</v-icon>
                  </v-btn>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card flat class="mb-6">
                  <v-card-text>
                    <v-list three-line subheader>
                      <h3 class="ml-4 mt-5">Availability</h3>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="title"
                            >Set your timezone</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >A correct timezone is essential to coordinate
                            lessons with international
                            students</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >Choose your timezone</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >Require password for purchase or use password to
                            restrict purchase</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content class="mt-4">
                          <v-list-item-title class="title"
                            >Set your availability</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >Availability shows your potential working
                            hours.Students can book lessons at these
                            times.</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-alert
                            text
                            dense
                            color="teal"
                            icon="mdi-clock-fast"
                            border="left"
                          >
                            <v-list-item-title
                              >Add peak hours to get more
                              students</v-list-item-title
                            >
                            <v-list-item-subtitle
                              >Many students book lessons between 6:00 and 9:00.
                              Add time slots during these peak hours to increase
                              your chances of getting
                              booked.</v-list-item-subtitle
                            >
                          </v-alert>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action>
                          <v-checkbox v-model="Monday"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Monday</v-list-item-title>
                          <v-row align="center" class="mt-1">
                            <v-col cols="6" sm="3">
                              <v-select
                                outlined
                                :items="hours"
                                placeholder="09:00"
                                dense
                              ></v-select>
                            </v-col>
                            <span class="title font-weight-light mb-4">to</span>
                            <v-col cols="6" sm="3">
                              <v-select
                                outlined
                                :items="hours"
                                placeholder="17:00"
                                dense
                              ></v-select>
                            </v-col>
                            <v-col sm="1">
                              <v-btn outlined icon fab class="mb-5" small>
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action>
                          <v-checkbox v-model="Tuesday"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Tuesday</v-list-item-title>
                          <v-row align="center" class="mt-1">
                            <v-col cols="6" sm="3">
                              <v-select
                                outlined
                                width="35"
                                :items="hours"
                                placeholder="09:00"
                                dense
                              ></v-select>
                            </v-col>
                            <span class="title font-weight-light mb-4">to</span>
                            <v-col cols="6" sm="3">
                              <v-select
                                outlined
                                width="35"
                                :items="hours"
                                placeholder="17:00"
                                dense
                              ></v-select>
                            </v-col>
                            <v-col sm="1">
                              <v-btn outlined icon fab class="mb-5" small>
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action>
                          <v-checkbox v-model="Wednesday"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Wendsday</v-list-item-title>
                          <v-row align="center" class="mt-1">
                            <v-col cols="6" sm="3">
                              <v-select
                                outlined
                                width="35"
                                :items="hours"
                                placeholder="09:00"
                                dense
                              ></v-select>
                            </v-col>
                            <span class="title font-weight-light mb-4">to</span>
                            <v-col cols="6" sm="3">
                              <v-select
                                outlined
                                width="35"
                                :items="hours"
                                placeholder="17:00"
                                dense
                              ></v-select>
                            </v-col>
                            <v-col sm="1">
                              <v-btn outlined icon fab class="mb-5" small>
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action>
                          <v-checkbox v-model="Thursday"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Thursday</v-list-item-title>
                          <v-row align="center" class="mt-1">
                            <v-col cols="6" sm="3">
                              <v-select
                                outlined
                                width="35"
                                :items="hours"
                                placeholder="09:00"
                                dense
                              ></v-select>
                            </v-col>
                            <span class="title font-weight-light mb-4">to</span>
                            <v-col cols="6" sm="3">
                              <v-select
                                outlined
                                width="35"
                                :items="hours"
                                placeholder="17:00"
                                dense
                              ></v-select>
                            </v-col>
                            <v-col sm="1">
                              <v-btn outlined icon fab class="mb-5" small>
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action>
                          <v-checkbox v-model="Friday"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Friday</v-list-item-title>
                          <v-row align="center" class="mt-1">
                            <v-col cols="6" sm="3">
                              <v-select
                                outlined
                                width="35"
                                :items="hours"
                                placeholder="09:00"
                                dense
                              ></v-select>
                            </v-col>
                            <span class="title font-weight-light mb-4">to</span>
                            <v-col cols="6" sm="3">
                              <v-select
                                outlined
                                width="35"
                                :items="hours"
                                placeholder="17:00"
                                dense
                              ></v-select>
                            </v-col>
                            <v-col sm="1">
                              <v-btn outlined icon fab class="mb-5" small>
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action>
                          <v-checkbox v-model="Saturday"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Saturday</v-list-item-title>
                          <v-row align="center" class="mt-1">
                            <v-col cols="6" sm="3">
                              <v-select
                                outlined
                                width="35"
                                :items="hours"
                                placeholder="09:00"
                                dense
                              ></v-select>
                            </v-col>
                            <span class="title font-weight-light mb-4">to</span>
                            <v-col cols="6" sm="3">
                              <v-select
                                outlined
                                width="35"
                                :items="hours"
                                placeholder="17:00"
                                dense
                              ></v-select>
                            </v-col>
                            <v-col sm="1">
                              <v-btn outlined icon fab class="mb-5" small>
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action>
                          <v-checkbox v-model="Sunday"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Sunday</v-list-item-title>
                          <v-row align="center" class="mt-1">
                            <v-col cols="6" sm="3">
                              <v-select
                                outlined
                                width="35"
                                :items="hours"
                                placeholder="09:00"
                                dense
                              ></v-select>
                            </v-col>
                            <span class="title font-weight-light mb-4">to</span>
                            <v-col cols="6" sm="3">
                              <v-select
                                outlined
                                width="35"
                                :items="hours"
                                placeholder="17:00"
                                dense
                              ></v-select>
                            </v-col>
                            <v-col sm="1">
                              <v-btn outlined icon fab class="mb-5" small>
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>

                <v-row class="mb-3">
                  <v-btn class="ml-5" @click="e1 = 2">
                    <v-icon class="mr-2">mdi-arrow-left-bold-outline</v-icon>
                    Back
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mr-5"
                    dark
                    color="indigo darken-4"
                    @click="e1 = 1"
                  >
                    Send
                    <v-icon class="ml-2">mdi-check</v-icon>
                  </v-btn>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",

      HourRate: "",
      skypeKod: "",

      e1: 1,
      tabResume: null,
      hours: ["09:00", "10:00", "11:00", "12:00"],
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antigua &amp; Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia &amp; Herzegovina",
        "Botswana",
        "Brazil",
        "British Virgin Islands",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Cape Verde",
        "Cayman Islands",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Cote D Ivoire",
        "Croatia",
        "Cruise Ship",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Polynesia",
        "French West Indies",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kuwait",
        "Kyrgyz Republic",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Namibia",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Norway",
        "Oman",
        "Pakistan",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Pierre &amp; Miquelon",
        "Samoa",
        "San Marino",
        "Satellite",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "South Africa",
        "South Korea",
        "Spain",
        "Sri Lanka",
        "St Kitts &amp; Nevis",
        "St Lucia",
        "St Vincent",
        "St. Lucia",
        "Sudan",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        `Timor L'Este`,
        "Togo",
        "Tonga",
        "Trinidad &amp; Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks &amp; Caicos",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Venezuela",
        "Vietnam",
        "Virgin Islands (US)",
        "Yemen",
        "Zambia",
        "Zimbabwe"
      ],
      errorMessages: "",
      name: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      country: null,
      formHasErrors: false,
      Monday: "",
      Tuesday: "",
      Wednesday: "",
      Thursday: "",
      Friday: "",
      Saturday: "",
      Sunday: "",
      notifications: "",
      widgets: "",
      checkbox: true,
      checkbox1: true,
      checkbox2: false
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style lang="scss" scoped></style>
