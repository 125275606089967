<template>
  <div>
    <v-container>
      <div
        class="  header row ma-0 fill-height text-center align-center justify-center"
      >
        <div class="col col-12">
          <h1 class="text-h4 font-weight-500 mb-2 text-center white--text">
            .
          </h1>
          <div>
            <div class="v-responsive__content">
              <br />
            </div>
          </div>
          <br />
          <v-row justify="center">
            <v-col cols="12" xs="12" sm="10">
              <v-toolbar flat color="transparent">
                <v-text-field
                  solo
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search News"
                  single-line
                ></v-text-field>
              </v-toolbar>
            </v-col>
          </v-row>
        </div>
      </div>

      <v-row justify="center">
        <v-col cols="12" xs="12" sm="10">
          <v-card flat class="mx-auto mt-5  grey lighten-4">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="2">
                    <div justify="center" align="center">
                      <v-icon class="mt-5" size="50"
                        >mdi-help-circle-outline</v-icon
                      >
                    </div>
                  </v-col>

                  <v-col cols="10">
                    <v-list-item-title
                      class="title font-weight-light teal--text "
                      >Help for users</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      General information for every user of our
                      platform</v-list-item-subtitle
                    >
                    <div style="margin-left:-17px">
                      <v-list-item>
                        <v-avatar size="30" color="grey darken-3" class="mr-2">
                          <v-img
                            class="elevation-6"
                            alt=""
                            src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                          ></v-img>
                        </v-avatar>

                        <v-list-item-content
                          style="font-size:8px; font-weight:400"
                        >
                          <v-list-item-subtitle
                            >7 articles in this collection</v-list-item-subtitle
                          >
                          <v-list-item-subtitle>
                            Written by Stanislava M.
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" xs="12" sm="10">
          <v-card flat class="mx-auto  grey lighten-4">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="2">
                    <div justify="center" align="center">
                      <v-icon class="mt-5" size="50">mdi-cube-outline</v-icon>
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <v-list-item-title
                      class="title font-weight-light teal--text "
                      >Rules & Guidelines</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      Learn about Onlineogrenim core values that will help you
                      on your tutoring journey</v-list-item-subtitle
                    >
                    <div style="margin-left:-17px">
                      <v-list-item>
                        <v-avatar size="30" color="grey darken-3" class="mr-2">
                          <v-img
                            class="elevation-6"
                            alt=""
                            src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                          ></v-img>
                        </v-avatar>

                        <v-list-item-content
                          style="font-size:8px; font-weight:400"
                        >
                          <v-list-item-subtitle
                            >7 articles in this collection</v-list-item-subtitle
                          >
                          <v-list-item-subtitle>
                            Written by Stanislava M.
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" xs="12" sm="10">
          <v-card flat class="mx-auto mt-2 grey lighten-4">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="2">
                    <div justify="center" align="center">
                      <v-icon class="mt-5" size="50"
                        >mdi-account-box-outline</v-icon
                      >
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <v-list-item-title
                      class="title font-weight-light teal--text "
                      >Your profile</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      Find out how to manage your Onlineogrenim
                      profile</v-list-item-subtitle
                    >
                    <div style="margin-left:-17px">
                      <v-list-item>
                        <v-avatar size="30" color="grey darken-3" class="mr-2">
                          <v-img
                            class="elevation-6"
                            alt=""
                            src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                          ></v-img>
                        </v-avatar>

                        <v-list-item-content
                          style="font-size:8px; font-weight:400"
                        >
                          <v-list-item-subtitle
                            >7 articles in this collection</v-list-item-subtitle
                          >
                          <v-list-item-subtitle>
                            Written by Stanislava M.
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" xs="12" sm="10">
          <v-card flat class="mx-auto mt-2 grey lighten-4">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="2">
                    <div justify="center" align="center">
                      <v-icon class="mt-5" size="50">mdi-unity</v-icon>
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <v-list-item-title
                      class="title font-weight-light teal--text "
                      >Onlineogrenim Space</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      Walkthrough of our video platform</v-list-item-subtitle
                    >
                    <div style="margin-left:-17px">
                      <v-list-item>
                        <v-avatar size="30" color="grey darken-3" class="mr-2">
                          <v-img
                            class="elevation-6"
                            alt=""
                            src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                          ></v-img>
                        </v-avatar>

                        <v-list-item-content
                          style="font-size:8px; font-weight:400"
                        >
                          <v-list-item-subtitle
                            >7 articles in this collection</v-list-item-subtitle
                          >
                          <v-list-item-subtitle>
                            Written by Stanislava M.
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" xs="12" sm="10">
          <v-card flat class="mx-auto mt-2 grey lighten-4">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="2">
                    <div justify="center" align="center">
                      <v-icon class="mt-5" size="50">mdi-kodi</v-icon>
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <v-list-item-title
                      class="title font-weight-light teal--text "
                      >Scheduling and conducting lessons</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      Manage your lessons and schedule as a
                      professional</v-list-item-subtitle
                    >
                    <div style="margin-left:-17px">
                      <v-list-item>
                        <v-avatar size="30" color="grey darken-3" class="mr-2">
                          <v-img
                            class="elevation-6"
                            alt=""
                            src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                          ></v-img>
                        </v-avatar>

                        <v-list-item-content
                          style="font-size:8px; font-weight:400"
                        >
                          <v-list-item-subtitle
                            >7 articles in this collection</v-list-item-subtitle
                          >
                          <v-list-item-subtitle>
                            Written by Stanislava M.
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" xs="12" sm="10">
          <v-card flat class="mx-auto mt-2 grey lighten-4">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="2">
                    <div justify="center" align="center">
                      <v-icon class="mt-5" size="50"
                        >mdi-vector-circle-variant</v-icon
                      >
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <v-list-item-title
                      class="title font-weight-light teal--text "
                      >Managing requests as a student</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      How to manage your messages</v-list-item-subtitle
                    >
                    <div style="margin-left:-17px">
                      <v-list-item>
                        <v-avatar size="30" color="grey darken-3" class="mr-2">
                          <v-img
                            class="elevation-6"
                            alt=""
                            src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                          ></v-img>
                        </v-avatar>

                        <v-list-item-content
                          style="font-size:8px; font-weight:400"
                        >
                          <v-list-item-subtitle
                            >7 articles in this collection</v-list-item-subtitle
                          >
                          <v-list-item-subtitle>
                            Written by Stanislava M.
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" xs="12" sm="10">
          <v-card flat class="mx-auto grey lighten-4">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="2">
                    <div justify="center" align="center">
                      <v-icon class="mt-5" size="50"
                        >mdi-image-filter-none</v-icon
                      >
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <v-list-item-title
                      class="title font-weight-light teal--text "
                      >Money & Payments</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      All the finance-related articles</v-list-item-subtitle
                    >
                    <div style="margin-left:-17px">
                      <v-list-item>
                        <v-avatar size="30" color="grey darken-3" class="mr-2">
                          <v-img
                            class="elevation-6"
                            alt=""
                            src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                          ></v-img>
                        </v-avatar>

                        <v-list-item-content
                          style="font-size:8px; font-weight:400"
                        >
                          <v-list-item-subtitle
                            >7 articles in this collection</v-list-item-subtitle
                          >
                          <v-list-item-subtitle>
                            Written by Stanislava M.
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      items: [
        {
          header: "Today"
        },
        { divider: true },
        {
          avatar: "https://picsum.photos/250/300?image=660",
          title: "Meeting @ Noon",
          subtitle: `<span class="font-weight-bold">Spike Lee</span> &mdash; I'll be in your neighborhood`
        },
        {
          avatar: "https://picsum.photos/250/300?image=821",
          title: 'Summer BBQ <span class="grey--text text--lighten-1"></span>',
          subtitle:
            '<span class="font-weight-bold">to Operations support</span> &mdash; Wish I could come.'
        },
        {
          avatar: "https://picsum.photos/250/300?image=783",
          title: "Yes yes",
          subtitle:
            '<span class="font-weight-bold">Bella</span> &mdash; Do you have Paris recommendations'
        },
        {
          header: "Yesterday"
        },
        { divider: true },
        {
          avatar: "https://picsum.photos/250/300?image=1006",
          title: "Dinner tonight?",
          subtitle:
            '<span class="font-weight-bold">LaToya</span> &mdash; Do you want to hang out?'
        },
        {
          avatar: "https://picsum.photos/250/300?image=146",
          title: "So long",
          subtitle:
            '<span class="font-weight-bold">Nancy</span> &mdash; Do you see what time it is?'
        },
        {
          header: "Last Week"
        },
        { divider: true },
        {
          avatar: "https://picsum.photos/250/300?image=1008",
          title: "Breakfast?",
          subtitle:
            '<span class="font-weight-bold">LaToya</span> &mdash; Do you want to hang out?'
        },
        {
          avatar: "https://picsum.photos/250/300?image=839",
          title:
            'Winter Porridge <span class="grey--text text--lighten-1"></span>',
          subtitle:
            '<span class="font-weight-bold">cc: Daniel</span> &mdash; Tell me more...'
        },
        {
          avatar: "https://picsum.photos/250/300?image=145",
          title: "Oui oui",
          subtitle:
            '<span class="font-weight-bold">Nancy</span> &mdash; Do you see what time it is?'
        }
      ]
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style>
.header {
  background-image: url("https://th.bing.com/th/id/OIP.eKV9gPC6IR262C0_kqO4WgHaGW?pid=ImgDet&rs=1");
}
</style>
