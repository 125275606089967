import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Secret from "../views/Secret.vue";
import List from "../views/List.vue";
import Avatars from "../views/Avatars.vue";
import Appmessages from "../views/Appmessages.vue";
import Detay from "../views/Detay.vue";
import Invoices from "../views/Invoices.vue";
import Settings from "../views/Settings.vue";
import Profile from "../views/Profile.vue";
import BecomeTutor from "../views/BecomeTutor.vue";
import RequestTutor from "../views/RequestTutor.vue";
import Help from "../views/Help.vue";
import Referral from "../views/Referral.vue";

import firebase from "firebase/app";
import "firebase/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/list",
    name: "list",
    props: true,
    component: List
  },
  {
    path: "/secret",
    name: "secret",
    component: Secret,
    meta: { requiresAuth: true }
  },
  {
    path: "/detay",
    name: "detay",
    props: true,
    component: Detay,
    meta: { requiresAuth: true }
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: { requiresAuth: true }
  },
  {
    path: "/invoices",
    name: "invoices",
    component: Invoices,
    meta: { requiresAuth: true }
  },
  {
    path: "/register",
    name: "register",
    component: Register
  },
  {
    path: "/avatars",
    name: "avatars",
    component: Avatars,
    meta: { requiresAuth: true }
  },
  {
    path: "/appmessages",
    name: "appmessages",
    component: Appmessages,
    meta: { requiresAuth: true }
  },
  {
    path: "/becometutor",
    name: "becometutor",
    component: BecomeTutor
  },
  {
    path: "/requesttutor",
    name: "requesttutor",
    component: RequestTutor
  },
  {
    path: "/help",
    name: "help",
    component: Help
  },
  {
    path: "/referral",
    name: "referral",
    component: Referral
  },
  {
    path: "/about",
    name: "About",
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
 // mode: "history",
  //base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = firebase.auth().currentUser;
  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else {
    next();
  }
});

export default router;
