import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import firebase from "firebase/app";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

var firebaseConfig = {
  apiKey: "AIzaSyDp8dom-kV9R7vHNe1LQ0GrHJbEUFg20EQ",
  authDomain: "finux-332eb.firebaseapp.com",
  databaseURL: "https://finux-332eb.firebaseio.com",
  projectId: "finux-332eb",
  storageBucket: "finux-332eb.appspot.com",
  messagingSenderId: "441439522148",
  appId: "1:441439522148:web:e4874f5b71f6aae9267e71",
  measurementId: "G-74KLZCZHEF"
};

firebase.initializeApp(firebaseConfig);

let app;

firebase.auth().onAuthStateChanged(user => {
  console.log(user);
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount("#app");
  }
});
