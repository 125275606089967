<template>
  <v-app dark>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      fixed
      app
      width="200px"
      src=""
      class="ml-1"
    >
      <v-list dense>
        <v-list-item-group
          v-model="model"
          mandatory
          color="grey--dark"
          outlined
        >
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :to="item.to"
            router
            exact
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="body-2" v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="appmessages" router exact v-if="windowSize.x < 950">
            <v-list-item-action>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <span class="body-2">Messages</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else to="avatars" router exact>
            <v-list-item-action>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <span class="body-2">Messages</span>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="clipped" fixed app flat>
      <v-btn v-if="!drawer" class="" icon large @click.stop="drawer = !drawer"
        ><v-icon>mdi-menu</v-icon></v-btn
      >
      <v-btn v-if="drawer" class="" icon large @click.stop="drawer = !drawer"
        ><v-icon>mdi-close</v-icon></v-btn
      >
      <v-btn v-if="0" icon fab @click.stop="drawer = !drawer">
        <v-icon color="indigo darken-4" size="36">mdi-chart-pie</v-icon></v-btn
      >

      <v-btn to="/" icon>
        <v-avatar class="ml-2" size="32"
          ><v-img
            src="https://its.unl.edu/images/services/icons/RemoteSupport_Icon-01.png"
          ></v-img>
        </v-avatar>
      </v-btn>

      <v-row>
        <v-col offset="1">
          <a
            @click="findtutors"
            class="black--text  body-2"
            style="text-decoration:none"
            >Find Tutors</a
          >
        </v-col>

        <v-col v-if="user" cols="6">
          <a
            @click="appmessages"
            class="hidden-md-and-up  black--text body-2"
            style="text-decoration:none"
            >Messages</a
          >
          <a
            @click="messages"
            class="hidden-sm-and-down  black--text body-2"
            style="text-decoration:none"
            >Messages</a
          >
          <a
            @click="lessons"
            class="  black--text body-2 ml-5"
            style="text-decoration:none"
            >Lessons</a
          >
        </v-col>
        <v-col v-else cols="6" class="hidden-sm-and-down">
          <a
            @click="requesttutor"
            class="  black--text body-2"
            style="text-decoration:none"
            >Request a tutor</a
          >
          <a
            @click="becometutor"
            class=" black--text body-2 ml-5"
            style="text-decoration:none"
            >Become a tutor</a
          >
        </v-col>
      </v-row>

      <v-spacer />

      <div class="hidden-md-and-down" v-if="user">
        <v-icon>mdi-credit-card</v-icon>
        <a class="ml-5 black--text mr-5 body-2" style="text-decoration:none"
          >Balance : 0 Hours</a
        >
        <v-btn
          to="invoices"
          style="text-decoration:none;margin-right:100px"
          class="indigo darken-4"
          dark
          small
          >Buy Hours</v-btn
        >
      </div>
      <div class="hidden-sm-and-down">
        <v-btn to="avatars" icon medium fab
          ><v-icon>mdi-comment-outline</v-icon></v-btn
        >
        <v-btn to="help" icon medium>
          <v-icon>mdi-help-circle-outline</v-icon></v-btn
        >
        <v-btn to="help" icon medium class="mr-4"
          ><v-icon>mdi-bell-outline</v-icon></v-btn
        >
      </div>

      <v-menu transition="scale-transition" rounded="rounded" bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="hidden-sm-and-down"
            dark
            icon
            v-bind="attrs"
            v-on="on"
            fab
          >
            <v-avatar size="32">
              <v-img :src="user.photoURL"></v-img>
            </v-avatar>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item v-for="(item, i) in items" :key="i" :to="item.to">
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <top-header small></top-header>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <div id="app">
        <v-progress-linear
          :active="progress"
          color="lime"
          indeterminate
          reverse
        ></v-progress-linear>
        <router-view />
      </div>
    </v-main>
    <v-navigation-drawer v-model="rightDrawer" :right="right" temporary fixed>
      <v-list>
        <v-list-item @click.native="right = !right">
          <v-list-item-action>
            <v-icon light>
              mdi-repeat
            </v-icon>
          </v-list-item-action>
          <v-list-item-title>Switch drawer (click me)</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-footer :absolute="!fixed" app class="hidden-sm-and-down">
      <span>&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
    <v-bottom-navigation
      v-if="0"
      class="hidden-md-and-up"
      :value="value"
      color="indigo darken-4"
      fixed
    >
      <v-btn to="list">
        <span>Find tutors</span>

        <v-icon>mdi-account-box-outline</v-icon>
      </v-btn>

      <v-btn to="avatars">
        <span>Messages</span>

        <v-icon>mdi-comment-outline</v-icon>
      </v-btn>

      <v-btn to="secret">
        <span>Lessons</span>

        <v-icon>mdi-bookmark-outline</v-icon>
      </v-btn>
      <v-btn to="settings">
        <span>Settings</span>

        <v-icon>mdi-settings-box</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-row v-resize="onResize" v-show="0"> </v-row>
  </v-app>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import TopHeader from "./components/Top-Header";
export default {
  components: {
    TopHeader
  },
  data() {
    return {
      progress: false,
      windowSize: {
        x: 0,
        y: 0
      },
      value: 1,
      user: {},
      model: "",
      clipped: true,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: "mdi-magnify",
          title: "Find tutors",
          to: "/list"
        },
        {
          icon: "mdi-settings",
          title: "Ayarlar",
          to: "/settings"
        },

        {
          icon: "mdi-calendar",
          title: "Calendar",
          to: "/about"
        },
        {
          icon: "mdi-book-open",
          title: "Become a tutor",
          to: "/becometutor"
        },

        {
          icon: "mdi-message-text-outline",
          title: "Request tutor",
          to: "/requesttutor"
        },

        {
          icon: "mdi-help-circle-outline",
          title: "Help",
          to: "/help"
        }
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Vuetify.js"
    };
  },
  mounted() {
    this.onResize();
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      this.loggedIn = !!user;
      this.user = user;
    });
  },
  methods: {
    becometutor() {
      this.$router.replace({ name: "becometutor" });
    },
    requesttutor() {
      this.$router.replace({ name: "requesttutor" });
    },
    messages() {
      this.$router.replace({ name: "avatars" });
    },
    appmessages() {
      this.$router.replace({ name: "appmessages" });
    },
    lessons() {
      this.$router.replace({ name: "secret" });
    },
    findtutors() {
      this.$router.replace({ name: "list" });
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    }
  }
};
</script>

<style lang="scss">
a:hover {
  -webkit-text-fill-color: rgb(156, 152, 152);
}
rgb(39, 9, 9)rgb(124, 21, 21) .greyback {
  background: rgb(233, 235, 235);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.menu {
  color: #141414;
  text-decoration: none;
  position: relative;
  font-family: "poppins", sans-serif;
}
</style>
