<template>
  <div class="grey lighten-3">
    <v-container fluid>
      <v-row justify="center">
        <v-col  xs="12" sm="12" md="7">
          <v-card dense>
            <v-card-title class="indigo darken-4">
              <span class=" white--text"> Request </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form>
                  <v-row>
                    <v-col cols="4"> </v-col>
                    <v-col cols="8">
                      <span class="title font-weight-bold"
                        >Describe your needs</span
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="hidden-xs-only">
                      <v-subheader>Tutoring subject</v-subheader>
                    </v-col>
                    <v-col cols="12" xs="12" sm="8">
                      <v-text-field
                        dense
                        maxlength="100"
                        label="Subject"
                        hint="Specify subject(e.g. Math, English) here"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="0">
                    <v-col cols="4" class="hidden-xs-only">
                      <v-subheader>Headline</v-subheader>
                    </v-col>
                    <v-col cols="12" xs="12" sm="8">
                      <v-text-field
                        dense
                        maxlength="100"
                        outlined
                        label="Write your headline in English"
                        hint='Good example:"LGS soru çözümleri"'
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="hidden-xs-only">
                      <v-subheader>Requirements</v-subheader>
                    </v-col>
                    <v-col cols="12" xs="12" sm="8">
                      <v-textarea
                        label="Specify additional details(e.g. your schedule) here"
                        outlined
                        hint="400 characters minimum, 0 characters currently"
                        persistent-hint
                        counter="400"
                        dense
                        small-chips
                        maxlength="100"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="hidden-xs-only">
                      <v-subheader>Price range</v-subheader>
                    </v-col>
                    <v-col cols="4" xs="4" sm="3">
                      <v-text-field
                        label="Min price"
                        dense
                        maxlength="3"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="mt-2" cols="1">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col cols="4" xs="4" sm="3">
                      <v-text-field
                        label="Max price"
                        dense
                        maxlength="3"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="mt-2" cols="1">
                      <v-icon>mdi-currency-try</v-icon>
                    </v-col>
                  </v-row>

                  <v-row class="my-4">
                    <v-col cols="4" class="hidden-xs-only"></v-col>
                    <v-col cols="12" xs="12" sm="8">
                      <a @click="details = !details">
                        <span>
                          <span v-show="details"
                            ><v-icon size="18" class="font-weight-bold"
                              >mdi-minus</v-icon
                            >
                            Hide</span
                          >
                          <span v-show="!details"
                            ><v-icon size="18" class="font-weight-bold"
                              >mdi-plus</v-icon
                            >
                            Specify</span
                          >
                          the age, level, and specialty of the student</span
                        ></a
                      >
                    </v-col>
                  </v-row>
                  <div v-show="details">
                    <v-row>
                      <v-col cols="4" class="hidden-xs-only">
                        <v-subheader>Specialty</v-subheader>
                      </v-col>
                      <v-col cols="12" xs="12" sm="8">
                        <v-autocomplete
                          dense
                          color="grey darken-2"
                          v-model="values"
                          :items="states"
                          outlined
                          clearable
                          chips
                          small-chips
                          label="Specialty..."
                          multiple
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="4" class="hidden-xs-only">
                        <v-subheader>Age of student</v-subheader>
                      </v-col>
                      <v-col cols="12" xs="12" sm="8">
                        <v-select
                          dense
                          :items="ages"
                          label="Age of student"
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="4" class="hidden-xs-only">
                        <v-subheader>Level of student</v-subheader>
                      </v-col>
                      <v-col cols="12" xs="12" sm="8">
                        <v-select
                          dense
                          :items="levels"
                          label="Level of student"
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                  </div>

                  <v-row>
                    <v-col cols="4" class="hidden-xs-only"> </v-col>
                    <v-col cols="12" xs="12" sm="8">
                      <span class="title">Contact information</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="hidden-xs-only">
                      <v-subheader>Your Name</v-subheader>
                    </v-col>
                    <v-col cols="12" xs="12" sm="8">
                      <v-text-field
                        dense
                        maxlength="100"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="hidden-xs-only">
                      <v-subheader>Email</v-subheader>
                    </v-col>
                    <v-col cols="12" xs="12" sm="8">
                      <v-text-field
                        dense
                        maxlength="100"
                        label="Email address"
                        type="email"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="hidden-xs-only"> </v-col>
                    <v-col cols="12" xs="12" sm="8">
                      <v-btn class="indigo darken-4" dark
                        ><v-icon class="mr-2">mdi-arrow-right-thick</v-icon> Save and continue
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row class="mt-3">
                    <v-col cols="4" class="hidden-xs-only"></v-col>
                    <v-col cols="12" xs="12" sm="8">
                      <span class="caption">
                        By clicking Save and ontiniue, you agree o Preply's
                        <span class="teal--text">Terms of Service</span> and
                        <span class="teal--text"> Privacy Policy</span>
                      </span>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    values: "",
    details: false,
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    levels: [
      "- not selected -",
      "Beginner",
      "Pre Intermediate",
      "Intermediate",
      "Upper Intermediate",
      "Advanced",
      "Proficiency",
      "Not Specified"
    ],
    ages: [
      "- not selected -",
      "Toddlers (1-3)",
      "Preschoolers (4-6)",
      "Primary school (6-12)",
      "Secondary school (12-17)",
      "Undergraduates (17-22)",
      "Adults (23-40)",
      "Adults (40+)"
    ],
    states: [
      "Alabama",
      "Alaska",
      "American Samoa",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Federated States of Micronesia",
      "Florida",
      "Georgia",
      "Guam",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Marshall Islands",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Northern Mariana Islands",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Palau",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virgin Island",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming"
    ]
  }),
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
